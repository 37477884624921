import { LocationMarkerIcon } from "@heroicons/react/outline";
import dynamic from "next/dynamic";
const ReactApexChart = dynamic(() => import("react-apexcharts"), {
  ssr: false,
});
import { ApexOptions } from "apexcharts";
import { Trans, t } from "@lingui/macro";
import MySpaceProfileItem from "./MySpaceProfileItem";
import { useEffect, useState } from "react";
import {
  useGetMySpaceChartDataQuery,
  useGetMySpaceKpisDataQuery,
  useGetMySpaceKpisDataRangeQuery,
} from "@/lib/services/suppliers";
import { getMySpaceChartData } from "@/lib/utils/chartUtils";
import Image from "next/image";
import Select from "react-select";
import moment from "moment";
import {
  OptionsForSelects,
  moneyFormatCurrency,
  monthsBetweenDates,
} from "@/lib/utils";
import { UserData } from "@/lib/dto/user";
import NoDataChart from "../../Management/NoDataChart";
import StackedLoading from "@/components/Management/StackedLoading";
import MyProfilePerformanceItem from "./MyProfilePerformanceItem";
import { useRouter } from "next/router";

interface Props {
  user?: UserData;
}

export default function MySpaceProfile({ user }: Props) {
  const locale = useRouter().locale;
  const [currentMonth, setCurrentMonth] = useState<OptionsForSelects>();
  const [months, setMonths] = useState<OptionsForSelects[]>();

  const [chartKind, setChartKind] = useState<"results" | "attention">(
    "results"
  );

  const { data, isLoading, isFetching } = useGetMySpaceChartDataQuery(
    {
      kind: chartKind,
      supplier_user_id: user?.id ?? 0,
    },
    { skip: !user?.id }
  );

  const chartData = getMySpaceChartData(data, locale);
  const {
    data: kpiData,
    isLoading: isLoadingKpis,
    isFetching: isFetchingKpis,
    error: errorKpis,
  } = useGetMySpaceKpisDataQuery(
    {
      supplier_user_id: user?.id ?? 0,
      date: currentMonth?.value ? currentMonth?.value + "-01" : "",
    },
    {
      skip: !user?.id || !currentMonth,
    }
  );

  const isLoadingKpisData = isLoadingKpis || isFetchingKpis || !user;

  const { data: kpisDataRange } = useGetMySpaceKpisDataRangeQuery(
    user?.id ?? 0,
    {
      skip: !user?.id,
    }
  );

  useEffect(() => {
    let datestart = moment(kpisDataRange?.start_date);
    let dateend = moment(kpisDataRange?.end_date);

    if (!datestart.isValid() || !dateend.isValid()) return;

    setMonths(monthsBetweenDates(datestart, dateend).reverse());
  }, [kpisDataRange]);

  useEffect(() => {
    if (months) {
      setCurrentMonth(months[0]);
    }
  }, [months]);

  const yaxis =
    chartData && chartData.series.length > 0
      ? [
          {
            show: false,
          },
          {
            show: false,
          },
          {
            show: false,
          },
        ]
      : [];

  const options: ApexOptions = {
    series: chartData.series,
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: chartData.categories,
    xaxis: {
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Nunito Sans",
          fontWeight: 600,
          colors: "#787878",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: yaxis,
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      markers: {
        radius: 6,
        offsetY: 0,
        offsetX: -2,
      },
      labels: {
        useSeriesColors: true,
      },
      fontFamily: "Nunito Sans",
      fontSize: "14px",
      fontWeight: 600,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60px",
        borderRadius: chartKind === "results" ? 13 : 10,
        rangeBarOverlap: true,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        formatter: (value: number, opts: any) => {
          if (chartKind === "results" && opts.seriesIndex in [0, 1]) {
            return moneyFormatCurrency(value, locale);
          }
          return `${value}`;
        },
      },
    },
  };

  const graphIsEmpty = (): boolean => {
    let sum = 0;
    chartData.series.forEach((serie) => {
      sum += serie.data.length;
    });
    return sum === 0;
  };

  const isLoadingGraph = isLoading || isFetching || !user;
  const noDataGraph = !isLoadingGraph && graphIsEmpty();

  const currentMonthYear = moment().format("YYYY-MM");
  const canShowTrend = currentMonth?.value
    ? currentMonth.value < currentMonthYear
    : false;

  return (
    <div>
      <div className="bg-tiffany-blue-10 p-2 rounded-t-lg">
        <div className="flex">
          {user?.user?.avatar_url && (
            <div className="w-30">
              <div className="w-20 h-20 rounded-full border-2 border-primary p-0.5">
                <Image
                  src={user?.user?.avatar_url}
                  width={150}
                  height={150}
                  objectFit="cover"
                  alt="name"
                  className="rounded-full"
                />
              </div>
            </div>
          )}
          <div className="ml-2 w-full flex-cols">
            <div className="w-full flex justify-between items-center">
              <h2 className="text-xl font-semibold">
                {user && user?.user.first_name + " " + user?.user.last_name}
              </h2>
              {user?.user.service_area && (
                <div className="flex bg-blue-300 text-xs text-blue-700 rounded-full px-3 py-2">
                  <LocationMarkerIcon className="h-4 w-4 mr-1" />
                  <span>{user.user.service_area.name}</span>
                </div>
              )}
            </div>
            <div className="flex items-center gap-2">
              {user?.user?.phone && (
                <label className="text-sm">{user?.user?.phone}</label>
              )}
              {user?.user?.email && (
                <label className="text-sm">{user?.user?.email}</label>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="grid grid-cols-2 bg-tiffany-blue-20 rounded-md h-10 mt-2 ">
            <div
              onClick={() => setChartKind("results")}
              className={`${
                chartKind === "results"
                  ? "bg-white rounded-md shadow hover:shadow-md "
                  : "hover:bg-white hover:rounded-md hover:shadow-md "
              } m-1 px-4 text-sm col-span-1 cursor-pointer flex items-center justify-center`}
            >
              <Trans>Results</Trans>
            </div>
            <div
              onClick={() => setChartKind("attention")}
              className={`${
                chartKind === "attention"
                  ? "bg-white rounded-md shadow hover:shadow-md "
                  : "hover:bg-white hover:rounded-md hover:shadow-md "
              } mr-1 my-1 text-sm col-span-1 cursor-pointer flex items-center justify-center`}
            >
              <Trans>Care</Trans>
            </div>
          </div>
        </div>
        {isLoadingGraph && <StackedLoading name={chartKind} />}
        {!isLoadingGraph && !graphIsEmpty() && (
          <ReactApexChart
            key={chartKind}
            options={options}
            series={options.series}
            type={"line"}
            height={300}
          />
        )}
        {noDataGraph && <NoDataChart />}
      </div>
      <div>
        <div className="dashboard-selects w-60 mx-2">
          <div className="relative">
            <Select
              placeholder={t`Select`}
              options={months}
              className="bg-light-blue-crayola"
              value={currentMonth}
              onChange={(item) => setCurrentMonth(item as OptionsForSelects)}
            />
            <span className="material-icons-outlined text-dark-jungle absolute top-2 left-2 z-[999]">
              date_range
            </span>
          </div>
        </div>
        <div className="mt-4 grid grid-cols-2 gap-4 px-2">
          <div className="col-span-1">
            <MySpaceProfileItem
              title={t`Assigned Req`}
              currentValue={kpiData?.assignated_leads.current_month}
              growth={kpiData?.assignated_leads.growth}
              icon="inventory"
              color="text-blue-500"
              isLoading={isLoadingKpisData}
              error={!!errorKpis}
              showTrend={canShowTrend}
            />
          </div>
          <div className="col-span-1">
            <MySpaceProfileItem
              title={t`Budgets sent`}
              currentValue={kpiData?.generated_budgets.current_month}
              growth={kpiData?.generated_budgets.growth}
              icon="request_quote"
              color="text-purple-500"
              isLoading={isLoadingKpisData}
              error={!!errorKpis}
              showTrend={canShowTrend}
            />
          </div>
          <div className="col-span-1">
            <MySpaceProfileItem
              title={t`Business won`}
              currentValue={kpiData?.won_leads.current_month}
              growth={kpiData?.won_leads.growth}
              icon="task_alt"
              color="text-yellow-400"
              isLoading={isLoadingKpisData}
              error={!!errorKpis}
              showTrend={canShowTrend}
            />
          </div>
          <div className="col-span-1">
            <MySpaceProfileItem
              title={t`Administration machines`}
              currentValue={kpiData?.aum.current_month}
              growth={kpiData?.aum.growth}
              icon="local_shipping"
              color="text-orange-400"
              isLoading={isLoadingKpisData}
              error={!!errorKpis}
              showTrend={canShowTrend}
            />
          </div>
          <div className="col-span-1">
            <MySpaceProfileItem
              title={t`Total billing`}
              currentValue={kpiData?.net_billing.current_month}
              growth={kpiData?.net_billing.growth}
              icon="monetization_on"
              color="text-green-400"
              isLoading={isLoadingKpisData}
              error={!!errorKpis}
              hasMoneyFormat
              showTrend={canShowTrend}
              locale={locale}
            />
          </div>
          <div className="col-span-1">
            <MySpaceProfileItem
              title={t`Total margin`}
              currentValue={kpiData?.net_margin.current_month}
              growth={kpiData?.net_margin.growth}
              hasMoneyFormat
              icon="handshake"
              color="text-red-500"
              isLoading={isLoadingKpisData}
              error={!!errorKpis}
              showTrend={canShowTrend}
              locale={locale}
            />
          </div>
        </div>
        <div className="my-4 mx-2 bg-white p-4 rounded-lg shadow-md">
          <div className="flex items-center">
            <span className={`material-icons-outlined text-orange-500`}>
              percent
            </span>
            <h2 className="ml-2 text-xl font-semibold">{t`Performance`}</h2>
          </div>
          <div className="mt-2 grid grid-cols-2 divide-x ">
            <div className="col-span-1 w-full flex flex-col items-center justify-center">
              <MyProfilePerformanceItem
                title={t`Budget tax`}
                currentValue={kpiData?.budget_conversion_rate.current_month}
                growth={kpiData?.budget_conversion_rate.growth}
                isLoading={isLoadingKpisData}
                error={!!errorKpis}
                showTrend={canShowTrend}
              />
              <div className="border w-full bg-gray-200 my-4"></div>
              <MyProfilePerformanceItem
                title={t`Close rate`}
                currentValue={kpiData?.lost_rate.current_month}
                growth={kpiData?.lost_rate.growth}
                isLoading={isLoadingKpisData}
                error={!!errorKpis}
                showTrend={canShowTrend}
              />
            </div>
            <div className="col-span-1 w-full flex flex-col items-center justify-center">
              <MyProfilePerformanceItem
                title={t`Lost rate`}
                currentValue={kpiData?.won_conversion_rate.current_month}
                growth={kpiData?.won_conversion_rate.growth}
                isLoading={isLoadingKpisData}
                error={!!errorKpis}
                showTrend={canShowTrend}
              />
              <div className="border w-full bg-gray-200 my-4"></div>
              <MyProfilePerformanceItem
                title={t`Total margin`}
                currentValue={kpiData?.net_margin_percentage.current_month}
                growth={kpiData?.net_margin_percentage.growth}
                isLoading={isLoadingKpisData}
                error={!!errorKpis}
                showTrend={canShowTrend}
              />
            </div>
          </div>
          <div className="mt-4">
            <span className="text-primary">*</span>
            <span className="text-xs text-gray-700 italic">
              {t`Compared to the previous month`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
