export default function NoTableData({ message }: { message: string }) {
  return (
    <div className="bg-white h-36 flex flex-col items-center justify-center space-y-4 text-gray-500">
      <span className="material-icons-outlined">table_chart</span>
      <p className="text-center text-sm text-gray-500 font-semibold">
        {message}
      </p>
    </div>
  );
}
