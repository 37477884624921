import { GoPage } from "@/lib/dto/requests";
import { useGetScheduleQuery } from "@/lib/services/leads";
import { Trans, t } from "@lingui/macro";
import { useEffect, useState } from "react";
import SkeletonTable from "../Utils/SkeletonTable";
import { Schedule } from "@/lib/dto/leads";
import { getScheduleStatusTranslated } from "@/lib/utils/translateUtils";
import Link from "next/link";
import NoTableData from "./Tables/NoTableData";
import { PAGE_SIZE_MY_SPACE } from "@/lib/services/config";

interface Props {
  supplier_user_id?: number;
}

export default function MySpaceSchedule({ supplier_user_id }: Props) {
  const statuses = [
    { value: "delivery_pending", label: t`Delivery pending` },
    { value: "expired", label: t`Expired` },
    { value: "expiring", label: t`To expire` },
  ];
  const [selectedStatus, setSelectedStatus] = useState<{
    value: string;
    label: string;
  }>(statuses[0]);
  const [goPage, setGoPage] = useState<GoPage>({
    page_size: PAGE_SIZE_MY_SPACE,
  });
  const { data, isLoading, isFetching } = useGetScheduleQuery(goPage, {
    skip: !supplier_user_id,
  });
  const isLoadingData =
    isLoading ||
    !supplier_user_id ||
    (isFetching && data && goPage.page_size === PAGE_SIZE_MY_SPACE);
  const isLoadingNewItem =
    isFetching && data && goPage.page_size !== PAGE_SIZE_MY_SPACE;

  const showPageCount = () => {
    if (data?.count) {
      if (goPage.page_size && data.count < goPage.page_size) {
        return data?.count;
      }
      return goPage.page_size;
    }
    return 0;
  };

  const showDangerousExpiration = (schedule: Schedule) => {
    if (!schedule.expiration || schedule.status === "finished") return false;
    return schedule.expiration < 0;
  };

  const showWarningExpiration = (schedule: Schedule) => {
    if (!schedule.expiration) return false;
    return schedule.expiration < 5 && schedule.expiration >= 0;
  };

  const getExpiration = (schedule: Schedule) => {
    if (!schedule.expiration || schedule.status === "finished") return "--";
    if (schedule.expiration === 0) return `${t`Today`}`;
    if (schedule.expiration < 0)
      return `${t`Expirated`} (${Math.abs(schedule.expiration)} ${t`days`})`;
    return `${schedule.expiration} ${t`days`}`;
  };

  const handleStatusChange = (status: { value: string; label: string }) => {
    setSelectedStatus(status);
    let newFilters = {};
    if (status.value === "delivery_pending") {
      newFilters = {
        assigned_to_supplier_user_id: supplier_user_id,
        status: status.value,
      };
    } else {
      newFilters = {
        assigned_to_supplier_user_id: supplier_user_id,
        expiration: status.value,
      };
    }
    setGoPage({
      page_size: PAGE_SIZE_MY_SPACE,
      filters: newFilters,
    });
  };

  useEffect(() => {
    if (supplier_user_id) {
      setGoPage({
        page_size: PAGE_SIZE_MY_SPACE,
        filters: {
          ...goPage.filters,
          assigned_to_supplier_user_id: supplier_user_id,
        },
      });
    }
  }, [supplier_user_id]);

  const getNoTableDataMessage = () => {
    if (selectedStatus.value === "delivery_pending") {
      return t`You don't have any delivery pending schedules`;
    }
    if (selectedStatus.value === "expired") {
      return t`You don't have any expired schedules`;
    }
    return t`You don't have any schedules to expire`;
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex-1">
          <h1 className="text-lg font-semibold text-gray-800">{t`Plan`}</h1>
          <Link href="/schedule">
            <a href="#" className="text-primary text-sm underline">
              <Trans>Go to schedule</Trans>
            </a>
          </Link>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="grid grid-cols-3 gap-x-1 bg-blue-200 rounded-md h-10 mt-2 px-1 w-full">
            {statuses.map((status) => (
              <div
                key={`myspace_schedule_status_${status.value}`}
                onClick={() => handleStatusChange(status)}
                className={`${
                  status.value === selectedStatus.value
                    ? "bg-white rounded-md shadow hover:shadow-md "
                    : "hover:bg-white hover:rounded-md hover:shadow-md "
                } my-1 px-1 text-sm col-span-1 cursor-pointer flex items-center justify-center w-full`}
              >
                {status.label}
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 flex items-center justify-end">
          <div className="text-sm text-gray-600">
            {t`Showing`}
            <span className="mx-1">
              {showPageCount()} {t`of`} {data?.count ?? 0}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-2 overflow-hidden border border-gray-400 shadow sm:rounded-lg">
        <table className="divide-y divide-gray-100 w-full">
          <thead className="bg-gray-800 text-white">
            <tr className="grid grid-cols-6">
              <th
                scope="col"
                className="text-sm font-medium text-left text-white col-span-1 p-3"
              >
                ID
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-left text-white col-span-1 p-3"
              >
                <Trans>Client</Trans>
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-left text-white col-span-1 p-3"
              >
                <Trans>Product</Trans>
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-left text-white col-span-1 p-3"
              >
                <Trans>Supplier</Trans>
              </th>

              <th
                scope="col"
                className="text-sm font-medium text-left text-white col-span-1 p-3"
              >
                <Trans>Expiration</Trans>
              </th>

              <th
                scope="col"
                className="text-sm font-medium text-left text-white col-span-1 p-3"
              >
                <Trans>Status</Trans>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 w-full">
            {isLoadingData && (
              <SkeletonTable
                colSpan={6}
                length={(goPage.page_size ?? 1) + 1}
                tdClasses="col-span-1 py-2 w-full"
                trClasses="grid grid-cols-6 w-full"
              />
            )}
            {!isLoadingData &&
              data?.results.map((schedule: Schedule) => (
                <tr
                  key={`myspace_schedule_${schedule.id}`}
                  className="grid grid-cols-6"
                >
                  <td className="text-sm font-medium text-gray-900 col-span-1 p-3">
                    <a
                      className="text-sm font-medium hover:text-gray-900 underline text-primary"
                      href={`/leads/${schedule.lead_id}/new-detail`}
                    >
                      {schedule.lead?.id || "--"}
                    </a>
                    <div className="text-sm font-medium text-gray-900">
                      {schedule.lead?.lease_number || "--"}
                    </div>
                  </td>
                  <td className="text-sm text-gray-500 col-span-1 p-3">
                    <td>
                      <div className="text-sm font-medium text-gray-900">
                        {schedule.lead?.organization_name || "--"} <br />{" "}
                        <span className="text-gray-500 truncate">
                          {schedule.lead?.organization_dni || "--"}
                        </span>
                      </div>
                    </td>
                  </td>
                  <td className="text-sm text-gray-500 col-span-1 p-3">
                    <div className="text-sm font-medium text-gray-900">
                      {schedule.category?.name || "--"} <br />
                      <span className="text-gray-500">
                        {schedule.category?.parent?.name || "--"}
                      </span>
                    </div>
                  </td>
                  <td className="text-sm text-gray-500 col-span-1 p-3">
                    <div className="text-sm font-medium text-gray-900">
                      {schedule.supplier?.name || "--"}
                    </div>
                  </td>
                  <td className="text-sm text-gray-500 col-span-1 p-3">
                    <div className="text-sm font-medium text-gray-900 flex items-center justify-center">
                      {showWarningExpiration(schedule) ? (
                        <span className="material-icons-outlined text-selective-yellow mr-2">
                          warning_amber
                        </span>
                      ) : showDangerousExpiration(schedule) ? (
                        <span className="material-icons-outlined text-fiery-rose mr-2">
                          dangerous
                        </span>
                      ) : null}
                      {getExpiration(schedule)}
                    </div>
                  </td>
                  <td className="text-sm text-gray-500 col-span-1 p-3">
                    <div className="flex items-center">
                      <div
                        className={`text-xs font-medium text-center whitespace-nowrap rounded-full px-2.5 py-0.5 ${
                          schedule.status === "active"
                            ? "text-green-600 bg-green-100"
                            : schedule.status === "finished"
                            ? "text-red-600 bg-red-100"
                            : schedule.status === "returned"
                            ? "bg-blue-100 text-blue-600"
                            : schedule.status === "delivery_pending"
                            ? "bg-yellow-100 text-yellow-600"
                            : "bg-gray-100"
                        }`}
                      >
                        {schedule.status &&
                          getScheduleStatusTranslated(schedule.status)}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {!isLoadingData && !isLoadingNewItem && data?.results.length === 0 && (
          <NoTableData message={getNoTableDataMessage()} />
        )}
        {isLoadingNewItem && (
          <div className="animate-pulse mx-3 grid grid-cols-6 my-2">
            <div className="col-span-1 mr-2">
              <div className="h-3 bg-gray-200 rounded w-full"></div>
              <div className="h-2 bg-gray-200 rounded w-full mt-1"></div>
            </div>
            <div className="col-span-1 mr-2">
              <div className="h-3 bg-gray-200 rounded w-full"></div>
              <div className="h-2 bg-gray-200 rounded w-full mt-1"></div>
            </div>
            <div className="col-span-1 mr-2">
              <div className="h-3 bg-gray-200 rounded w-full"></div>
              <div className="h-2 bg-gray-200 rounded w-full mt-1"></div>
            </div>
            <div className="col-span-1 mr-2">
              <div className="h-3 bg-gray-200 rounded w-full"></div>
              <div className="h-2 bg-gray-200 rounded w-full mt-1"></div>
            </div>
            <div className="col-span-1 mr-2">
              <div className="h-3 bg-gray-200 rounded w-full"></div>
              <div className="h-2 bg-gray-200 rounded w-full mt-1"></div>
            </div>
            <div className="col-span-1 mr-2">
              <div className="h-3 bg-gray-200 rounded w-full"></div>
              <div className="h-2 bg-gray-200 rounded w-full mt-1"></div>
            </div>
          </div>
        )}
        {!isLoadingData && !isLoadingNewItem && data?.next_page && (
          <div>
            <div className="w-full bg-gray-200 border-t border-gray-200"></div>
            <div className="w-full flex justify-center p-3 bg-white hover:bg-gray-100 cursor-pointer">
              <button
                onClick={() => {
                  setGoPage({
                    ...goPage,
                    page_size: (goPage.page_size ?? 1) + 1,
                  });
                }}
                className="text-primary"
              >
                <Trans>Show more</Trans>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
