import moment from "moment";
import { MySpaceChartData } from "../dto/dashboard";
import { t } from "@lingui/macro";
import { getCountryCodeFromLocale } from "../utils";

export const getMySpaceChartData = (
  data?: MySpaceChartData,
  locale?: string
) => {
  if (!data) {
    return {
      categories: [],
      series: [],
    };
  }

  const databarTranslation = [
    {
      label: "net_billing",
      translation: t`Net Billing`,
      type: "bar",
      color: "#256EFF",
    },
    {
      label: "net_margin",
      translation: t`Net Margin`,
      type: "bar",
      color: "#9747FF",
    },
    {
      label: "net_margin_percentage",
      translation: t`Net Margin %`,
      type: "line",
      color: "#17C3B2",
    },
    {
      label: "assignated_leads",
      translation: t`Assignated Leads`,
      type: "bar",
    },
    {
      label: "generated_budgets",
      translation: t`Generated Budgets`,
      type: "bar",
    },
    {
      label: "won_leads",
      translation: t`Won Leads`,
      type: "bar",
    },
  ];

  const newSeries = data.series.map((serie) => {
    const databar = databarTranslation.find((bar) => bar.label === serie.name);
    if (databar) {
      return {
        ...serie,
        type: databar.type,
        name: databar.translation,
        color: databar.color,
        borderWidth: 0,
        borderRadius: 50,
        borderSkipped: false,
      };
    }
    return serie;
  });
  const newCategories = data.categories.map((category) => {
    const date = moment(category + "01")
      .locale(locale ? getCountryCodeFromLocale(locale) : "es")
      .format("MMM")
      .replace(".", "");
    return date.charAt(0).toUpperCase() + date.slice(1);
  });
  return {
    categories: newCategories,
    series: newSeries,
  };
};

export const dashboardLatamDefaultFilters = [
  "lease_req",
  "sale_req",
  "leases",
  "sales",
  "total_net",
  "leases_net",
  "sales_net",
  "net_margin",
  "net_margin_leases",
  "net_margin_sales",
  "percent_margin",
  "percent_margin_leases",
  "percent_margin_sales",
  "close_rate",
  "equipments",
  "lease_days",
  "lease_average_days",
  "customers_per_month",
  "suppliers_accumulated",
];

export const dashboardLatamFilters = [
  {
    label: t`Req. Lease`,
    key: "lease_req",
  },
  {
    label: t`Req. Sale`,
    key: "sale_req",
  },
  {
    label: t`Leases`,
    key: "leases",
  },
  {
    label: t`Sales`,
    key: "sales",
  },
  {
    label: t`Total net`,
    key: "total_net",
  },
  {
    label: t`Leases net`,
    key: "leases_net",
  },
  {
    label: t`Sales net`,
    key: "sales_net",
  },
  {
    label: t`Net margin`,
    key: "net_margin",
  },
  {
    label: t`Net margin leases`,
    key: "net_margin_leases",
  },
  {
    label: t`Net margin sales`,
    key: "net_margin_sales",
  },
  {
    label: t`Total margin` + " (%)",
    key: "percent_margin",
  },
  {
    label: t`percent margin leases`,
    key: "percent_margin_leases",
  },
  {
    label: t`percent margin sales`,
    key: "percent_margin_sales",
  },
  {
    label: t`Close rate`,
    key: "close_rate",
  },
  {
    label: t`Lease equipments`,
    key: "equipments",
  },
  {
    label: t`Lease days`,
    key: "lease_days",
  },
  {
    label: t`Prom days new lease`,
    key: "lease_average_days",
  },
  {
    label: t`Customers per month`,
    key: "customers_per_month",
  },
  {
    label: t`Suppliers accumulated`,
    key: "suppliers_accumulated",
  },
];
