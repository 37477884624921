import { GoPage } from "@/lib/dto/requests";
import { GeoUnit } from "./geounit";
import { Carrier } from "./carriers";
import { t } from "@lingui/macro";
import { LeadIndustry } from "./leads";
export interface ImagesPayload {
  images: FormData;
  productId: number;
}

export interface ProductImageUpdatePayload {
  id: number;
  is_public: boolean;
}

export interface ProductImage {
  id?: number;
  image_url?: string;
  image?: string;
  is_featured?: boolean;
  is_public: boolean;
  file?: File;
  kind?: string;
}

export interface NewProduct {
  model_id: number;
  year: number;
  serial_number: string;
  supplier_internal_id: string;
  is_active: boolean;
  is_active_reason: string | null;
  images: ProductImage[];
  available_for_lease: boolean;
  available_for_sale: boolean;
  supplier_sale_price: number;
}

export interface Attribute {
  key: string;
  value: any;
}

export enum CategoryPriceType {
  PER_DAY = "per_day",
  PER_HOUR = "per_hour",
}

export interface Category {
  id: number;
  name: string;
  title?: string;
  internal_use?: InternalUseType;
  parent_id: number;
  daily_price: number;
  weekly_price: number;
  monthly_price: number;
  image_url: string;
  image?: string;
  slug: string;
  attributesList: Attribute[];
  thumbnails: Thumbnails;
  price_type?: CategoryPriceType;
  parent?: Category;
  country_id?: string;
  industry?: LeadIndustry;
  kind?: CategoryKind;
  is_active?: boolean;
  is_featured?: boolean;
  is_public?: boolean;
  estimated_transportation_price?: number;
  images?: CategoryImage[];
  floor_price?: number;
  hourly_price?: number;
}

export interface CategoryImage {
  id?: number;
  image: string;
  is_featured?: boolean;
  file?: File;
}

export enum CategoryKind {
  LEASE = "lease",
  SALE = "sale",
}

export interface CategoryPayload {
  name?: string;
  title?: string;
  parent_id?: number;
  daily_price?: number;
  weekly_price?: number;
  monthly_price?: number;
  industry?: LeadIndustry;
  price_type?: CategoryPriceType;
  is_active?: boolean;
  is_featured?: boolean;
  is_public?: boolean;
  estimated_transportation_price?: number;
  images?: CategoryImage[];
  hourly_price?: number;
  internal_use?: InternalUseType;
}

export const getCategoryIndustryTranslated = (
  industry: LeadIndustry
): string => {
  switch (industry) {
    case LeadIndustry.CONSTRUCTION:
      return t`Construction`;
    case LeadIndustry.INDUSTRIAL:
      return t`Industry`;
    default:
      return t`Other`;
  }
};

export interface Brand {
  id: number;
  name: string;
}

export interface BrandPayload {
  name: string;
}

export interface BrandModel {
  id: number;
  name: string;
  category_id?: number;
  categories?: Category[];
  brand?: Brand;
}

export interface BrandModelPayload {
  brand_id?: number;
  name?: string;
  category_id?: number;
}

export enum ProductStatus {
  AVAILABLE = "Available",
  NOTAVAILABLE = "NotAvailable",
  LEASED = "Leased",
  LEASED_BY_RENDALOMAQ = "LeasedByRendalomaq",
  OUT_OF_RANGE = "OutOfRange",
}

export interface Supplier {
  classification: string;
  id: number;
  name: string;
  kind: string;
  machine_kind: string;
  dni: string;
  phone: string;
  email: string;
  address: string;
  is_rendalomaq: boolean;
  contact_name?: string;
  country_id: string | undefined | null;
  geo_unit_id: number | undefined | null;
  geo_unit: GeoUnit | undefined | null;
  tax_value?: number;
}

export interface CreateSupplier {
  classification: string;
  name: string;
  email: string;
  kind: string;
  machine_kind: string;
  dni: string;
  phone: string;
  address: string;
  is_rendalomaq: boolean;
  contact_name?: string;
  country_id: string | undefined | null;
  geo_unit_id: number | undefined | null;
  geo_unit: GeoUnit | undefined | null;
}

export interface CreateLeaseDetail {
  id?: number;
  lead_detail_id?: number;
  supplier_id?: number;
  category_id?: number | null;
  supplier_price?: number;
  price?: number;
  start_date?: string;
  end_date?: string;
  supplier_product_id?: number;
  reason?: string;
  price_type?: string;
  minimum_hours?: number;
  transportation_price?: number;
  supplier_transportation_id?: number;
  supplier_transportation_price?: number;
  supplier_transportation?: Supplier;
}

interface Thumbnails {
  image_url: string;
  large_url: string;
  medium_url: string;
  small_url: string;
}

interface ProductMetaData {
  value: number;
  last_update: string;
}

export enum ProductConsiderationKind {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
}

export interface ProductConsideration {
  id?: number | null;
  text: string;
  kind: ProductConsiderationKind;
}

export enum ProductGPSPhoneProvider {
  CLARO_CL = "CLARO_CL",
  ENTEL_CL = "ENTEL_CL",
  MOVISTAR_CL = "MOVISTAR_CL",
  VIRGIN_CL = "VIRGIN_CL",
}

export interface ProductGPS {
  id?: number;
  horometer_base?: number;
  odometer_base?: number;
  imei?: string;
  phone_number?: string;
  phone_provider?: ProductGPSPhoneProvider;
  model_id?: string;
}

export interface ProductGPSModel {
  id: number;
  name: string;
}

export interface ProductForSaleData {
  characteristics?: string;
  description?: string;
  specifications?: string;
  technical_data?: string;
  year?: number;
  model?: BrandModel;
  image?: string;
}

export interface Product {
  supplier_branch_id?: number;
  supplier_branch?: Branch;
  currency: string;
  id: number;
  name?: string;
  product?: ProductForSaleData;
  serial_number: string | null;
  category: Category | null;
  approved_for_sale?: boolean;
  available_for_lease?: boolean;
  available_for_sale?: boolean;
  image_url?: string;
  category_id?: number | null;
  brand_id?: number | null;
  model_id?: number | null;
  product_id?: number | null;
  year?: number | null;
  status: ProductStatus;
  supplier_internal_id?: string;
  supplier?: Supplier | null;
  order_id?: number;
  order_supplier_internal_id?: number;
  image?: string;
  images: ProductImage[];
  thumbnails?: Thumbnails;
  total_horometer?: number;
  total_odometer?: number;
  odometer?: ProductMetaData;
  horometer?: ProductMetaData;
  point?: Point;
  searchName?: string;
  supplier_sale_price?: number;
  public_sale_price?: number;
  considerations?: ProductConsideration[];
  credit_payment?: boolean;
  inspection_file?: string;
  previous_usage?: string;
  is_new?: boolean;
  gps?: ProductGPS;
  horometer_base?: number;
  odometer_base?: number;
}

export interface ProductAvailabilityUpdate {
  available_for_lease: boolean;
  available_for_sale: boolean;
}

export interface ModelDetail {
  categories: Category[];
}

export interface Point {
  lat: number;
  lng: number;
}

export interface GeoPoint extends Point {
  x: number;
  y: number;
}

interface PositionAttributes {
  batteryLevel?: string;
  ignition?: boolean;
  motion?: boolean;
}

interface Position {
  valid?: boolean;
  attributes?: PositionAttributes;
  speed?: number;
}

interface Device {
  lastUpdate?: string;
  model?: string;
  status?: string;
  uniqueId?: string;
}

export interface PositionData {
  position?: Position;
  device?: Device;
}

export interface SummaryData {
  total_horometer?: Number;
  total_odometer?: Number;
}

export interface ProductLocation extends Partial<Product> {
  point: GeoPoint;
  position_data?: PositionData;
  summary_data?: SummaryData;
  availability: string;
  is_online?: boolean;
  image: string;
}

export interface ProductLocationGroup {
  point: GeoPoint;
  products: ProductLocation[];
}

export interface LocationGoPage extends GoPage {
  category_id: string | null;
  supplier_id: string | null;
  availability: string | null;
  shared_key?: string;
}

export interface AttributeFilter {
  name: string;
  values: string[];
}

export enum CommentKind {
  ORDER = "order",
  LEAD = "lead",
  ORDER_DETAIL = "orderdetail",
}

export interface Comment {
  id: number;
  text: string;
  date: string;
  object_id: number;
  comment_kind: CommentKind;
}

export interface CommentPayload {
  text?: string;
  date?: string;
  comment_kind?: string;
}

export interface NewProductOtherSupplier {
  supplier_branch?: Branch;
  supplier_branch_id?: number;
  available_for_sale?: boolean;
  available_for_lease?: boolean;
  public_sale_price?: number;
  supplier_sale_price?: number;
  brand_id?: number;
  supplier_id?: number;
  model_id?: number;
  year?: number;
  supplier_internal_id?: string;
  serial_number?: string;
  previous_usage?: string;
  inspection_file?: File;
  category_id?: number;
  is_new?: boolean;
  currency: string;
}

export const currencyKinds = [
  {
    label: "CLP",
    value: "CLP",
    locale: ["es-CL"],
  },
  {
    label: "UF",
    value: "UF",
    locale: ["es-CL"],
  },
  {
    label: "MXN",
    value: "MXN",
    locale: ["es-MX"],
  },
  {
    label: "BRL",
    value: "BRL",
    locale: ["pt-BR"],
  },
  {
    label: "USD",
    value: "USD",
    locale: ["es-CL", "es-MX", "pt-BR"],
  },
];

export const currencyKindsByLocale = (locale?: string) => {
  return currencyKinds.filter((currency) =>
    currency.locale.includes(locale || "es-CL")
  );
};

export const availabilityKinds = [
  {
    label: t`Not available`,
    value: "not_available",
  },
  {
    label: t`Only rent`,
    value: "rent",
  },
  {
    label: t`Only sell`,
    value: "sell",
  },
  {
    label: t`Sale and lease`,
    value: "rent_and_sell",
  },
];

export const phoneProviderOptions = [
  {
    label: ProductGPSPhoneProvider.CLARO_CL,
    value: ProductGPSPhoneProvider.CLARO_CL,
  },
  {
    label: ProductGPSPhoneProvider.ENTEL_CL,
    value: ProductGPSPhoneProvider.ENTEL_CL,
  },
  {
    label: ProductGPSPhoneProvider.MOVISTAR_CL,
    value: ProductGPSPhoneProvider.MOVISTAR_CL,
  },
  {
    label: ProductGPSPhoneProvider.VIRGIN_CL,
    value: ProductGPSPhoneProvider.VIRGIN_CL,
  },
];

export const isNewKinds = [
  {
    label: t`New`,
    value: "new",
  },
  {
    label: t`Used`,
    value: "used",
  },
];

export enum InternalUseType {
  TRANSPORTATION = "transportation",
}

export interface SupplierProduct {
  id?: number;
  supplier_id: number;
  serial_number?: string;
  year?: number;
  brand_id?: number;
  model_id?: number;
  category_id?: number;
}

export interface Branch {
  id?: number;
  supplier_id?: number;
  name: string;
  geo_unit_id?: number;
  phone?: string;
  email?: string;
  address_street?: string;
  address_street_number?: string;
  address_reference?: string;
  point: Point;
  geo_unit?: GeoUnit;
  supplier?: Supplier;
}

export interface RendaloMarker extends google.maps.Marker {
  supplierProductId: number;
}
