import MySpaceTrendItem from "../MySpaceTrendItem";
import MyProfilePerformanceItemSkeleton from "./MyProfilePerformanceItemSkeleton";

interface Props {
  title: string;
  currentValue?: number;
  growth?: number;
  isLoading: boolean;
  error: boolean;
  showTrend?: boolean;
}

export default function MyProfilePerformanceItem({
  title,
  currentValue,
  growth,
  isLoading,
  error = false,
  showTrend = true,
}: Props) {
  if (isLoading) return <MyProfilePerformanceItemSkeleton />;
  let currentValueLocal = currentValue ? `${currentValue}%` : "--";
  if (error) {
    currentValueLocal = "--";
  }
  const canShowTrend = showTrend && growth !== undefined && !error;
  return (
    <div className="w-full flex flex-col items-center justify-center h-20">
      <div className="text-2xl font-semibold">{currentValueLocal}</div>
      <div className="text-base font-semibold">{title}</div>
      {canShowTrend ? (
        <MySpaceTrendItem growth={growth} shortText />
      ) : (
        <div></div>
      )}
    </div>
  );
}
