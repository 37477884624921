import { moneyFormatCurrency } from "@/lib/utils";
import MySpaceTrendItem from "../MySpaceTrendItem";
import MySpaceProfileItemSkeleton from "./MyProfileItemSkeleton";

interface Props {
  title: string;
  currentValue?: number;
  growth?: number;
  icon: string;
  color: string;
  isLoading: boolean;
  error: boolean;
  hasMoneyFormat?: boolean;
  showTrend?: boolean;
  locale?: string;
}

export default function MySpaceProfileItem({
  title,
  currentValue,
  growth,
  icon,
  color,
  isLoading,
  error = false,
  hasMoneyFormat = false,
  showTrend = true,
  locale,
}: Props) {
  if (isLoading) return <MySpaceProfileItemSkeleton />;
  let currentValueLocal = currentValue
    ? hasMoneyFormat
      ? moneyFormatCurrency(currentValue, locale)
      : currentValue
    : "--";
  if (error) {
    currentValueLocal = "--";
  }

  const canShowTrend = showTrend && growth !== undefined && !error;
  return (
    <div
      className={
        "bg-white p-4 rounded-lg shadow-md h-40 flex flex-col justify-between"
      }
    >
      <div className="flex items-center">
        <span className={`material-icons-outlined ${color}`}>{icon}</span>
        <h2 className="ml-2 text-lg font-semibold">{title}</h2>
      </div>
      <div className="w-full flex justify-center items-center">
        <label className="text-2xl font-semibold">{currentValueLocal}</label>
      </div>
      {canShowTrend ? (
        <MySpaceTrendItem growth={growth} />
      ) : (
        // This is necessary to keep the same height of the component
        <div></div>
      )}
    </div>
  );
}
