import { t } from "@lingui/macro";

interface Props {
  growth: number;
  shortText?: boolean;
}

export default function MySpaceTrendItem({ growth, shortText = false }: Props) {
  const trend = growth > 0 ? "up" : growth < 0 ? "down" : "equal";
  const trendColorClass =
    trend === "up"
      ? "bg-green-100 text-tiffany-blue"
      : trend === "down"
      ? "bg-red-100 text-radical-red"
      : "bg-gray-100 text-gray-500";
  const trendIcon =
    trend === "up"
      ? "trending_up"
      : trend === "down"
      ? "trending_down"
      : "trending_flat";

  const getTextTranslating = (trend: string) => {
    switch (trend) {
      case "up":
        return shortText ? t`more` : t`more than the previous month`;
      case "down":
        return shortText ? t`less` : t`less than the previous month`;
      default:
        return shortText ? t`equal` : t`equal to previous month`;
    }
  };

  const positiveGrowth = Math.abs(growth);
  const finalGrowth =
    positiveGrowth > 0 && positiveGrowth < 1
      ? positiveGrowth
      : positiveGrowth.toFixed(0);

  return (
    <div
      className={`mt-2 rounded-full ${trendColorClass} flex items-center px-2 text-xs w-fit`}
    >
      <span className="material-icons-outlined">{trendIcon}</span>
      {trend !== "equal" && (
        <span className="ml-1 text-xs">{finalGrowth}%</span>
      )}
      <span className="ml-1">{getTextTranslating(trend)}</span>{" "}
      {shortText && <span className="text-primary">*</span>}
    </div>
  );
}
