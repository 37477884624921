import { GoPage } from "@/lib/dto/requests";
import { Trans, t } from "@lingui/macro";
import { useEffect, useState } from "react";
import SkeletonTable from "../Utils/SkeletonTable";
import {
  useGetTasksQuery,
  useUpdateOperationTaskStatusMutation,
} from "@/lib/services/orders";
import { OperationTask, getOrderTaskKindTranslated } from "@/lib/dto/orders";
import moment from "moment";
import { Tooltip } from "flowbite-react";
import Link from "next/link";
import NoTableData from "./Tables/NoTableData";
import { PAGE_SIZE_MY_SPACE } from "@/lib/services/config";

interface Props {
  supplier_user_id?: number;
}

export default function MySpaceTasks({ supplier_user_id }: Props) {
  const today = moment().set({ hour: 0, minute: 0, second: 0 }).toISOString();
  const maxDate = moment()
    .add(3, "days")
    .set({ hour: 0, minute: 0, second: 0 })
    .toISOString();

  const [updateStatus] = useUpdateOperationTaskStatusMutation();

  const nextPayload = {
    page_size: PAGE_SIZE_MY_SPACE,
    filters: {
      done: false,
      start_date: today,
      end_date: maxDate,
      supplier_user_ids: [supplier_user_id],
    },
  };

  const unDonePayload = {
    page_size: PAGE_SIZE_MY_SPACE,
    filters: {
      done: false,
      end_date: today,
      supplier_user_ids: [supplier_user_id],
    },
  };

  const [goPage, setGoPage] = useState<GoPage>(unDonePayload);
  const { data, isLoading, isFetching } = useGetTasksQuery(goPage, {
    skip: !supplier_user_id,
  });
  const isLoadingData =
    isLoading ||
    !supplier_user_id ||
    (isFetching && data && goPage.page_size === PAGE_SIZE_MY_SPACE);
  const isLoadingNewItem =
    isFetching && data && goPage.page_size !== PAGE_SIZE_MY_SPACE;

  const showPageCount = () => {
    if (data?.count) {
      if (goPage.page_size && data.count < goPage.page_size) {
        return data?.count;
      }
      return goPage.page_size;
    }
    return 0;
  };

  const getNoTableDataMessage = () => {
    if (!goPage.filters?.start_date) {
      return t`You have no pending tasks`;
    }
    return t`You have no upcoming tasks`;
  };

  const handleTaskStatusChange = async (
    checked: boolean,
    task: OperationTask
  ) => {
    await updateStatus({
      task_id: task.id!,
      status: checked,
    });
  };

  useEffect(() => {
    if (supplier_user_id) {
      setGoPage({
        page_size: PAGE_SIZE_MY_SPACE,
        filters: {
          ...goPage.filters,
          supplier_user_ids: [supplier_user_id],
        },
      });
    }
  }, [supplier_user_id]);

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex-1">
          <h1 className="text-lg font-semibold text-gray-800">{t`Tasks`}</h1>
          <Link href="/tasks">
            <a href="#" className="text-primary text-sm underline">
              <Trans>Go to tasks</Trans>
            </a>
          </Link>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="grid grid-cols-2 bg-blue-200 rounded-md h-10 mt-2 w-full">
            <div
              onClick={() => {
                setGoPage(unDonePayload);
              }}
              className={`${
                !goPage.filters?.start_date
                  ? "bg-white rounded-md shadow hover:shadow-md"
                  : "hover:bg-white hover:rounded-md hover:shadow-md "
              } m-1 text-sm col-span-1 cursor-pointer flex items-center justify-center`}
            >
              <Trans>Undone</Trans>
            </div>
            <div
              onClick={() => {
                setGoPage(nextPayload);
              }}
              className={`${
                goPage.filters?.start_date
                  ? "bg-white rounded-md shadow hover:shadow-md"
                  : "hover:bg-white hover:rounded-md hover:shadow-md "
              } m-1 text-sm col-span-1 cursor-pointer flex items-center justify-center`}
            >
              <Trans>Next plural</Trans>
            </div>
          </div>
        </div>
        <div className="flex-1 flex items-center justify-end">
          <div className="text-sm text-gray-600">
            {t`Showing`}
            <span className="mx-1">
              {showPageCount()} {t`of`} {data?.count ?? 0}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-2 overflow-hidden border border-gray-400 shadow sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-100">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th
                scope="col"
                className="pl-3 py-3 text-sm font-medium tracking-wider text-left text-white"
              >
                ID
              </th>
              <th
                scope="col"
                className=" py-3 text-sm font-medium tracking-wider text-left text-white"
              >
                <Trans>Date</Trans>
              </th>
              <th
                scope="col"
                className=" py-3 text-sm font-medium tracking-wider text-left text-white"
              >
                <Trans>Client</Trans>
              </th>
              <th
                scope="col"
                className=" py-3 text-sm font-medium tracking-wider text-left text-white"
              >
                <Trans>Task</Trans>
              </th>

              <th
                scope="col"
                className=" py-3 text-sm font-medium tracking-wider text-left text-white"
              >
                <Trans>Notes</Trans>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {isLoadingData && (
              <SkeletonTable colSpan={5} length={(goPage.page_size ?? 1) + 1} />
            )}
            {!isLoadingData &&
              data?.results.map((task: OperationTask) => (
                <tr key={`myspace_task_${task.id}`}>
                  <td className="pl-3 py-4 text-sm font-medium text-gray-900">
                    <div className="flex items-center justify-center">
                      <Tooltip
                        content={t`Click here to mark this task as completed`}
                        trigger="hover"
                      >
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            handleTaskStatusChange(e.target.checked, task);
                          }}
                          defaultChecked={task.done}
                          className="mt-1 rounded border-gray-300 text-primary shadow-sm focus:border-primary focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                      </Tooltip>
                      <a
                        className="ml-4 underline text-primary"
                        href={`/leads/${task.object_id}/new-detail`}
                      >
                        {task.object_id}
                      </a>
                    </div>
                  </td>
                  <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                    <td>{moment(task.date).format("DD/MM/YYYY hh:mm a")}</td>
                  </td>
                  <td className="pl-3 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex items-center">
                      <div className="text-sm font-medium text-gray-900">
                        {task.contact_info?.organization_name
                          ? task.contact_info?.organization_name
                          : `${task.contact_info?.first_name} ${task.contact_info?.last_name}`}
                        {task.contact_info?.phone && (
                          <span className="text-gray-500">
                            <br />
                            {task.contact_info?.phone}
                          </span>
                        )}
                        {task.contact_info?.email && (
                          <span className="text-gray-500">
                            <br />
                            {task.contact_info?.email}
                          </span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex items-center">
                      <div className="text-sm font-medium text-gray-900">
                        {getOrderTaskKindTranslated(task.kind)}
                      </div>
                    </div>
                  </td>
                  <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex items-center">
                      <label className="text-sm font-medium text-gray-900 break-all">
                        {task.notes}
                      </label>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
          {isLoadingNewItem && <SkeletonTable colSpan={5} length={1} />}
        </table>
        {!isLoadingData && !isLoadingNewItem && data?.results.length === 0 && (
          <NoTableData message={getNoTableDataMessage()} />
        )}
        {!isLoadingData && !isLoadingNewItem && data?.next_page && (
          <div>
            <div className="w-full bg-gray-200 border-t border-gray-200"></div>
            <div className="w-full flex justify-center p-2 bg-white hover:bg-gray-100 cursor-pointer">
              <button
                onClick={() => {
                  setGoPage({
                    ...goPage,
                    page_size: (goPage.page_size ?? 1) + 1,
                  });
                }}
                className="text-primary"
              >
                <Trans>Show more</Trans>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
