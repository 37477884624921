/* Colors used in global dashboard */

export const clDashboardColor = "#256EFF";
export const mxDashboardColor = "#FF4062";
export const brDashboardColor = "#FAB900";

export const trendBgColor = (percentaje: number): String => {
  if (percentaje > 0) {
    return "bg-green-100";
  } else if (percentaje < 0) {
    return "bg-red-100";
  } else {
    return "bg-gray-100";
  }
};

export const getClassFromStatusColor = (color?: string) => {
  switch (color) {
    case "green":
      return "bg-green-100 text-green-800";
    case "light-green":
      return "bg-green-400 text-green-100";
    case "gray":
      return "bg-gray-100 text-gray-800";
    case "yellow":
      return "bg-yellow-100 text-yellow-800";
    case "purple":
      return "bg-purple-100 text-purple-800";
    case "blue":
      return "bg-blue-100 text-blue-800";
    case "red":
      return "bg-red-100 text-red-800";
    case "pink":
      return "bg-pink-100 text-pink-800";
    default:
      return "bg-gray-400";
  }
};

export const getClassFromStatusColorKanban = (color?: string) => {
  switch (color) {
    case "green":
      return "bg-green-400";
    case "light-green":
      return "bg-green-300";
    case "gray":
      return "bg-gray-400";
    case "yellow":
      return "bg-yellow-400";
    case "purple":
      return "bg-purple-400";
    case "blue":
      return "bg-blue-400";
    case "red":
      return "bg-red-400";
    case "pink":
      return "bg-pink-400";
    default:
      return "bg-gray-400";
  }
};

export const getBgColorByLeadKind = (kind?: string) => {
  switch (kind) {
    case "lease":
      return "bg-fiery-rose";
    default:
      return "bg-blue-crayola";
  }
};
